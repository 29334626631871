import { fromJS } from "immutable"
import { useEffect, useState } from "react";
import { fixed2Decimals, decimal2Fixed, getContract, getTokenIcon } from "../../../utils";
import { connect } from "react-redux";
import { muteQuoterABI, quickSwapQuoterABI, uniV3QuoterABI } from "../../../utils/abi";
import { Badge, Col, Row, Spinner } from "react-bootstrap";

function DexQuotes({web3, dexes, amount, tokenIn, tokenOut, op, updateEstimate}) {
    const [acitveDex, setActiveDex] = useState(null);
    const [recommended, setRecommended] = useState(null);
    const [quotes, setQuotes] = useState(fromJS({}));
    const [isFetching, setIsFetching] = useState(false);

    useEffect(()=> {
        const fetchQuotes = async (amount) => {
            let data = fromJS({});
            let active = null;
            for(var i=0; i< dexes.size ; i++) {
                const dex = dexes.get(i);
                const contract = getQuoterContract(dex);
                let quote = 0;
                if(dex.get("id") === "1") {//Mute.io
                    try {
                        if(op === "buy") { 
                            const res = await contract.methods.getAmountsIn(
                                decimal2Fixed(amount, tokenOut.get("decimals")),
                                [tokenIn.get("uAddress"), tokenOut.get("uAddress")]
                            ).call();
                            quote = res[0];
                        }
                        if(op === "sell") {
                            const res = await contract.methods.getAmountOut(
                                decimal2Fixed(amount, tokenIn.get("decimals")),
                                tokenIn.get("uAddress"),
                                tokenOut.get("uAddress"),
                            ).call();
                            console.log("quickswap quote", res);
                            quote = res.amountOut;
                        }
                    }
                    catch(err) {
                        console.log("Error uniswap quote", err);
                        quote=0;
                    }
               } 
            //    if(dex.get("id") === "1") {
            //         try {
            //             if(op === "buy") {
            //                 quote = await contract.methods.quoteExactOutputSingle(
            //                     tokenIn.get("uAddress"),
            //                     tokenOut.get("uAddress"),
            //                     decimal2Fixed(3, 3),
            //                     decimal2Fixed(amount, tokenOut.get("decimals")),
            //                     0 
            //                 ).call();
            //             }

            //             if(op === "sell") {
            //                 quote = await contract.methods.quoteExactInputSingle(
            //                     tokenIn.get("uAddress"),
            //                     tokenOut.get("uAddress"),
            //                     decimal2Fixed(3, 3),
            //                     decimal2Fixed(amount, tokenIn.get("decimals")),
            //                     0 
            //                 ).call();
            //                 console.log("v3 quote", quote);
            //             }
            //         }
            //         catch(err) {
            //             console.log("Error uniswap quote", err);
            //             quote=0;
            //         }
            //     } else if(dex.get("id") === "2") {
            //         try {
            //             if(op === "buy") { 
            //                 const res = await contract.methods.getAmountsIn(
            //                     decimal2Fixed(amount, tokenOut.get("decimals")),
            //                     [tokenIn.get("uAddress"), tokenOut.get("uAddress")]
            //                 ).call();
            //                 quote = res[0];
            //             }
            //             if(op === "sell") {
            //                 const res = await contract.methods.getAmountsOut(
            //                     decimal2Fixed(amount, tokenIn.get("decimals")),
            //                     [tokenIn.get("uAddress"), tokenOut.get("uAddress")]
            //                 ).call();
            //                 console.log("quickswap quote", res);
            //                 quote = res[1];
            //             }
                        
            //         } catch(err) {
            //             console.log("Error quickswap quote", err);
            //             quote=0;
            //         }
            //    }
               data = data.set(dex.get("id"), quote);
               let condition =  op === "buy" ? Number(data.get(active)) > Number(quote)  : Number(data.get(active)) < Number(quote);
               if(Number(quote) > 0) {
                   if(active == null) {
                        active = dex.get("id");
                   } else if(condition ) {
                        active = dex.get("id");
                   }
               }
            }
            setQuotes(data);
            setActiveDex(active);
            setRecommended(active);
            if(active) {
                updateEstimate({"dexId": active, quote: data.get(active)});
            }
            setIsFetching(false);
        }
        updateEstimate(null)
        setActiveDex(null);
        setQuotes(fromJS({}));
        setIsFetching(true);
        fetchQuotes(amount);
    }, [amount])

    const getQuoterContract = (dex)=> {
        let abi=[];
        // if(dex.get("id") === "1") {
        //     abi = uniV3QuoterABI;
        // } else if(dex.get("id") === "2") {
        //     abi = quickSwapQuoterABI;
        // }
        if(dex.get("id")=== "1") {
            abi = muteQuoterABI;
        }
        return getContract(web3, abi, dex.get("quoterAddress"));
    }
    const tokenDispay  = op === "buy" ? tokenIn : tokenOut;

    return (
        <div className="small dex-quotes">
            {isFetching ? <Spinner size="sm" variant="secondary" animation="grow"/> : dexes.map((dex, i)=> { 
                const quote = quotes.get(dex.get("id"));
                const isValidQuote = quote !==undefined && Number(quote)> 0 ? true : false;
                return <div key={i}>
                    <Row onClick={()=> {
                        if (isValidQuote) {
                            setActiveDex(dex.get("id"));
                            updateEstimate({dexId: dex.get("id"), quote: quote})
                        }
                    }} className="align-items-center cursor-pointer">
                        <Col md={1}>
                            <i className={`${acitveDex === dex.get("id") ? "fas fa-check-circle text-secondary" : "far fa-circle"} `}/>
                        </Col>
                        <Col>
                            <div className="text-light-2 small">
                                {dex.get('name')}
                            </div>
                            {isValidQuote && <div className="text-light-1 fw-bold">
                                <span><img src={getTokenIcon(tokenDispay.get("symbol"))} height="14" alt='token'/>&nbsp;{fixed2Decimals(quote, tokenDispay.get("decimals"), 4)}</span>
                            </div>}
                            {quote !==undefined && Number(quote) === 0 ? <span className="small text-light-1">Not enough liquidity</span>: null}
                            {dexes.size >1 && recommended === dex.get("id") && <div className="mt-1">
                                <Badge bg="primary">Recommended</Badge>
                            </div>}
                        </Col>
                    </Row>
                </div>
            })}
        </div>
    )
}

const mapStateToProps = (state)=> {
    return {
        web3: state.get('web3'),
        dexes: state.get('dexes')
    }
}

export default connect(mapStateToProps, null)(DexQuotes);