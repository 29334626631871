import { connect } from 'react-redux';
import { fixed2Decimals, getContract, getTokenIcon, toPrecisin } from '../utils';
import { Spinner} from 'react-bootstrap';
import { fetchAccountSnapshot, fetchNativeBalance, fetchTransactions } from '../redux/actions';
import { libABI } from '../utils/abi';
import BalanceHelp from './dashboard/components/modal/BalanceHelp';
import { useEffect, useState } from 'react';

function WalletBalances(props) {

    const [token, setToken] = useState(props.assets.find((item)=> item.get("lendEnabled")));
    const borrowAssets = props.assets.filter((item)=> item.get("lendEnabled"));
    useEffect(()=> {
        // borrowAssets.map(item => token === null && item.get("symbol") === "xUSDT" ? setToken(item) : "");
        // setToken();
    }, []);

    
    let underlyingBalance = 0;
    if ( token != null && props.uTokenMeta.hasIn([token.get("uAddress"), "balance"]) ) {
        underlyingBalance =  Number(props.uTokenMeta.getIn([token.get("uAddress"), "balance"]));
    }

    const refresh = () => {
        const contract = getContract(props.web3, libABI, props.libAddress);
        props.fetchAccountSnapshot(contract, props.accAddress, props.assets.map((t)=> t.get("address")).toJS());
        props.fetchNativeBalance(props.web3, props.accAddress);
        props.fetchTransactions("mumbai", props.accAddress);
    }

    return (
        <div className='text-end mb-4'>
            <div className='wallet-balances'>
                <span className='me-4 fw-normal'><i className='fa  fa-wallet text-secondary me-1'/>Wallet Balance</span>
                <span>
                    {props.isLoadingNativeBalance ? <Spinner size="sm"  animation="grow" variant="secondary"/> : toPrecisin(fixed2Decimals(props.nativeBalance, 18), 4)} ETH
                </span>
                
                {props.isLoadingUSDCBalance ? <Spinner size="sm"  animation="grow" variant="secondary"/> : borrowAssets.map((token, i) => {
                    if (props.uTokenMeta.hasIn([token.get("uAddress"), "balance"]) ) {
                        return <span key={i}>
                            <span className='ms-4 me-4'>|</span>
                            <img src={getTokenIcon(token.get("symbol"))} height="14" alt={token.get("symbol")} />
                            &nbsp;{fixed2Decimals(props.uTokenMeta.getIn([token.get("uAddress"), "balance"]), token.get("decimals"), 2)}
                        </span>
                    }
                })}
                <span onClick={()=> refresh()} title="refresh" className='ms-4 px-1 py-0 rounded-3 btn btn-primary'><i className='mx-1 fa fa-refresh'/></span>
            </div>
            {(props.nativeBalance === 0 || underlyingBalance === 0) && <div className="mt-2">
                <div style={{display: "inline-block"}}>
                    <BalanceHelp token={token} position="header"/>
                </div>
            </div>}
            {/* <div>
                <div className='text-center'>
                    <Row >
                        <Col className="bg-light">
                            <i className='fa fa-2x fa-wallet me-2 text-secondary'/>
                        </Col>
                        <Col>
                            <div className="text-light-2 fw-bold">{toPrecisin(fixed2Decimals(props.nativeBalance, 18), 4)} MATIC</div> 
                        </Col>
                        <Col>
                            | <span className='ms-2'>{toPrecisin(fixed2Decimals(usdcBalance, 6), 2)}USDC (Test Tokens) </span>
                        </Col>
                    </Row>
                </div>
            </div> */}

        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        web3: state.get("web3"),
        accAddress: state.get("accAddress"),
        libAddress: state.getIn(["contracts", "libABI"]),
        uTokenMeta: state.getIn(["balanceAndAllowances", "uTokens"]),
        assets: state.get("assets"),
        isLoadingNativeBalance: state.get("isLoadingNativeBalance"),
        nativeBalance: state.get("nativeBalance"),
        isLoadingUSDCBalance: state.getIn(["balanceAndAllowances", "isLoading"])
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchAccountSnapshot: (contract, accAddress, assets) => dispatch(fetchAccountSnapshot(contract, accAddress, assets)),
        fetchNativeBalance: (web3, address) => dispatch(fetchNativeBalance(web3, address)),
        fetchTransactions: (network, address) => dispatch(fetchTransactions(network, address)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WalletBalances);