import defaultState, { transaction, mainnetAccount } from './defaultState';
import { fromJS} from 'immutable';
import {decimal2Fixed, fixed2Decimals} from '../utils';
import { act } from 'react-dom/test-utils';

export default function reducer(state = fromJS(defaultState), action) {
    switch (action.type) {
        case "TOGGLE_THEME":
            return state = state.set("themeMode", action.mode);
        case "UPDT_ACCOUNT":
            return updateAccountData(state, action.data);
        case "REQ_NATIVE_BAL":
            return state.set("isLoadingNativeBalance", true);
        case "REC_NATIVE_BAL":
            state = state.set("isLoadingNativeBalance", false);
            return state.set("nativeBalance", action.balance);
        case "REQ_ACC_SNAPSHOT":
            state = state.setIn(["balanceAndAllowances", "isLoading"], true);
            return state.setIn(["accountSnapshot", "isLoading"], true);
        case "REC_ACC_SNAPSHOT":
            state = processAccountSnapshot(state, action.data);
            state = state.setIn(["balanceAndAllowances", "isLoading"], false);
            return state.setIn(["accountSnapshot", "isLoading"], false);
        // case "REQ_ALLOW_BAL":
        //     return state.setIn(["balanceAndAllowances", "isLoading"], true);
        // case "REC_ALLOW_BAL":
        //     state = processBalanceAndAllowances(state, action.data);
        //     return state.setIn(["balanceAndAllowances", "isLoading"], false);
        case "REQ_ESTIMATE":
            return state.setIn(["swapEstimates", action.data.targetToken, action.data.value, "isLoading"], true);
        case "REC_ESTIMATE":
            state = state.setIn(["swapEstimates", action.data.targetToken, action.data.value, "isLoading"], false);
            state = state.setIn(["swapEstimates", action.data.targetToken, action.data.value, "error"], action.error);
            return state.setIn(["swapEstimates", action.data.targetToken, action.data.value, "estimate"], action.result);
        case "REC_TOTAL_CASH":
            return state.setIn(["totalUnderlyingCash", action.address],  action.result);
        case "REQ_TRANS":
            state = state.set("transaction", fromJS({}));
            state = state.setIn(["transaction", "txDesc"], action.txDesc);
            state = state.setIn(["transaction", "isCompleted"], false);
            state = state.setIn(["transaction", "isActive"], true);
            return state.setIn(["transaction", "isLoading"], true);
        case "UPD_TRANS_ID":
            return state.setIn(["transaction", "txId"], fromJS(action.txId));
        case "REC_TRANS":
            state = state.setIn(["transaction", "response"], fromJS(action.response));
            state = state.setIn(["transaction", "isSuccess"], action.success);
            state = state.setIn(["transaction", "isCompleted"], true);
            return state.setIn(["transaction", "isLoading"], false);
        case "RESET_TRANS":
            return state.set("transaction", fromJS(transaction));
        case "RESET_MAINNET_ACC":
            return state.set("mainnetAccount", fromJS(mainnetAccount));
        case "REC_MAINNET_ACC":
            state = state.setIn(["mainnetAccount", "isFetched"], true);
            state = state.setIn(["mainnetAccount", "status"], action.data.status);
            return state.setIn(["mainnetAccount", "message"], action.data.message);
        case "TOGGLLE_MAINNET_ADDR_UPD":
            state = state.setIn(["mainnetAccount", "isUpdating"], action.status);
            return state.setIn(["mainnetAccount", "updateMessage"], action.message);
        case "TOGGLE_TRANS_DATA":
            return state.setIn(["transactions", "isLoading"], action.status);
        case "REC_TRANS_DATA":
            return state.setIn(["transactions", "data"], fromJS(action.data));
        case "REC_HIST_DATA":
            return state = state.setIn(["historicData", action.days.toString()], fromJS(action.data));
        case "TOGGLE_NFT_DATA":
            return state.setIn(["nftCollection", "isLoading"], action.status);
        case "REC_NFT_DATA":
            return state.setIn(["nftCollection", "data"], fromJS(action.data));
        case "TOGGLE_MINT_NFT_DATA":
            return state.setIn(["mintedCollection", "isLoading"], action.status);
        case "REC_MINT_NFT_DATA":
            return state.setIn(["mintedCollection", "data"], fromJS(action.data));
        default:
            return state;
    }
}

function updateAccountData(state, data) {
    let keys =  Object.keys(data);
    for(let i=0; i < keys.length; i++) {
        state = state.set(keys[i], data[keys[i]]);
    }
    return state;
}

function processAccountSnapshot(state, response) {
    const data = response.tokensData;
    const tokens = state.get("assets");
    // const lendBalances = data[0];
    // const borrowBalances = data[1];
    // const supplyRPBlock = data[2];
    // const borrowRPBlock = data[3];
    // const buyPercAll = data[4];

    // balanceOfUnderlying: "10567367611"
    // borrowBalanceCurrent: "3495235"
    // borrowRatePerBlock: "12385057779"
    // buyFactor: "2000000000000000000"
    // supplyRatePerBlockNerr: "1612432066"
    // totalBorrowsCurrent: "1416155911"
    // totalCash: "9461308729"
    //prepare lend balances
    let snapshot = fromJS({});
    let balances = fromJS({});
    for ( let i=0; i< tokens.size; i++) {
        const token = tokens.get(i);
        // snapshot = snapshot.setIn([tokens.get(i), "lendBalance"], lendBalances[i]);
        // snapshot = snapshot.setIn([tokens.get(i), "borrowBalance"], borrowBalances[i]);
        // snapshot = snapshot.setIn([tokens.get(i), "supplyRPBlock"], supplyRPBlock[i]);
        // snapshot = snapshot.setIn([tokens.get(i), "borrowRPBlock"], borrowRPBlock[i]);
        // snapshot = snapshot.setIn([tokens.get(i), "buyFactor"], fixed2Decimals(buyPercAll[i], 18));
        // var tmpLendAPY = (Math.pow(
        //     (supplyRPBlock[i] / decimal2Fixed(1, 18)) * 5760 + 1,
        //     365
        //     ) -
        //     1) *
        //     100;
        // var tmpBorrowAPY = (Math.pow(
        //     (borrowRPBlock[i] / decimal2Fixed(1, 18)) * 5760 + 1,
        //     365
        //     ) -
        //     1) *
        //     100;

        const row = data[i];
        // const lendTokens = 
        snapshot = snapshot.setIn([token.get("address"), "lendBalanceTokens"], Number(row.balanceOf));
        snapshot = snapshot.setIn([token.get("address"), "lendBalance"], Number(row.balanceOfUnderlying));
        snapshot = snapshot.setIn([token.get("address"), "borrowBalance"], Number(row.borrowBalanceStored));
        snapshot = snapshot.setIn([token.get("address"), "supplyRPBlock"], Number(row.supplyRatePerBlock));
        snapshot = snapshot.setIn([token.get("address"), "borrowRPBlock"], Number(row.borrowRatePerBlock));
        snapshot = snapshot.setIn([token.get("address"), "buyFactor"], fixed2Decimals(row.buyFactor, 18));
        snapshot = snapshot.setIn([token.get("address"), "totalCash"], Number(row.totalCash));
        snapshot = snapshot.setIn([token.get("address"), "totalBorrowsCurrent"], Number(row.totalBorrows));
        snapshot = snapshot.setIn([token.get("address"), "totalSupply"], Number(row.totalSupply));
        snapshot = snapshot.setIn([token.get("address"), "exchangeRateStored"], Number(row.exchangeRateStored));
        snapshot = snapshot.setIn([token.get("address"), "oraclePrice"], row.oraclePrice);

        snapshot = snapshot.setIn([token.get("address"), "underlyingBalance"], row.underlyingBalance);
        snapshot = snapshot.setIn([token.get("address"), "underlyingAllowance"], row.allowance);

        balances = balances.setIn([token.get("uAddress"), "balance"], row.underlyingBalance);
        balances = balances.setIn([token.get("uAddress"), "allowance"], row.allowance);

        var tmpLendAPY = (Math.pow(
            (Number(row.supplyRatePerBlock) / decimal2Fixed(1, 18)) * 7200 + 1,
            365
            ) -
            1) *
            100;
        var tmpBorrowAPY = (Math.pow(
            (Number(row.borrowRatePerBlock) / decimal2Fixed(1, 18)) * 7200 + 1,
            365
            ) -
            1) *
            100;
        snapshot = snapshot.setIn([token.get("address"), "lendAPY"], tmpLendAPY);
        snapshot = snapshot.setIn([token.get("address"), "borrowAPY"], tmpBorrowAPY);
    }
    state = state.set("accountSnapshot", snapshot);
    state = state.setIn(["balanceAndAllowances", "uTokens"], balances);
    state = state.setIn(["portfolio", "accountLiquidity"], response.accountLiquidity)
    state = state.setIn(["portfolio", "accountLiquidityDecimals"], fixed2Decimals(response.accountLiquidity, 18, 2, 1))
    state = state.setIn(["portfolio", "compAccrued"], fixed2Decimals(response.compAccrued, 18, 2))
    return state;
}
