
export const transaction = {
    isActive: false,
    isLoading: false,
    isCompleted: false
};

export const mainnetAccount =  {
    isFetched: false,
    status: 0,
    message: "",
    isUpdating: false,
    updateMessage: null
}

const protocolAssets = [
    {
        "name": "WeFi USDT",
        "symbol": "USDT",
        "address": "0x9358138473ea07Ad3954A2f7AF0872590cc0B13e",
        "uAddress": "0xeDad65E4a6FC6295fd298c58d8Ecee8Eb522a192",
        "decimals": 6,
        "lendEnabled": true,
        "borrowEnabled": true,
        "buyEnabled": false,
        "buyAssets": ["0x4418C7f99B5C499846665b4691A436Ceb38F87AE"]
    },
    {
        "name": "WeFi WETH",
        "symbol": "WETH",
        "address": "0x4418C7f99B5C499846665b4691A436Ceb38F87AE",
        "uAddress": "0x1996AadD7b156bf329cAC574A7182f35A885c131",
        "decimals": 18,
        "lendEnabled": false,
        "borrowEnabled": false,
        "buyEnabled": true,
        "borrowAssets": ["0x9358138473ea07Ad3954A2f7AF0872590cc0B13e"]
    }
];

const defaultState =  {
    themeMode: "dark",
    network: "zkSyncTestnet",
    connected: false,
    networkId: null,
    chainId: null,
    accAddress: "",
    web3: null,
    isLoadingNativeBalance: false,
    nativeBalance: -1,
    dexes: [
        {"name": "Mute.io", "id": "1", "quoterAddress": "0x96c2Cf9edbEA24ce659EfBC9a6e3942b7895b5e8"}
    ],
    contracts: {
        libABI: "0xB0e51dE0F33894ebC367d133aE026Ac9D4B91a2C",
        uniswapQuoter: "0x3F11A24EB45d3c3737365b97A996949dA6c2EdDf",
        comptroller: "0xEA8256b4df273450A31eC52f5ecCbF89ce3b5622",
        xdcFaucetAddress: "0xd6030E6f773df42ABa6EAe12833003fB2F21d02c",
    },
    supportedChains: [
        {
            name: "zkSync Era Testnet",
            shortName: "zkSyncTestnet",
            chain: "zkSyncTestnet",
            network: "",
            chainId: 280,
            networkId: 280,
            nativeCurrency: {
                symbol: "ETH",
                name: "ETH",
                decimals: "18",
                contractAddress: "0x000000000000000000000000000000000000800A",
                balance: ""
            },
            webURL: "https://goerli.explorer.zksync.io"
        },
    ],
    assets: protocolAssets,
    accountSnapshot: {
        isLoading: false
    },
    balanceAndAllowances: {
        isLoading: false,
        uTokens: {}
    },
    swapEstimates: {
    },
    totalUnderlyingCash: {

    },
    transaction,
    mainnetAccount,
    transactions: {
        isLoading: false,
        data: []
    },
    portfolio: {
        accountLiquidity: 0,
        accountLiquidityDecimals: 0,
        compAccrued: 0
    },
    historicData: {},
    nftCollection: {
        isLoading: false,
        data: []
    },
    mintedCollection : {
        isLoading: false,
        data: []
    }

}


export default defaultState;