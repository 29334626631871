import {Route, Routes} from  "react-router-dom";
import {useState, useEffect} from 'react';
import Web3 from "web3";
import Web3Modal, {providers} from "web3modal";
import {connect} from 'react-redux';

import {updateAccount, fetchAccountSnapshot, resetTransaction, fetchNativeBalance, 
    fetchTransactions, fetchHistoricData} from "./app/redux/actions";
import  {getContract} from './app/utils';
import NavBar from './app/layout/NavBar';
import Portfolio from './app/screens/portfolio/Portfolio';
import { libABI, nftABI } from "./app/utils/abi";
import TransactionModal from "./app/screens/dashboard/components/TransactionModal";
import ConnectHelp from "./app/screens/ConnectHelp";
import Lend from "./app/screens/lend/Lend";
import Borrow from "./app/screens/borrow/Borrow";

function App(props) {
    const [web3, setWeb3] = useState(null);
    const [web3Modal, setWeb3Modal] = useState(new Web3Modal({
        // network: props.network, // optional
        cacheProvider: true, // optional
        providerOptions: {
        } // required
    }));
    const [provider, setProvider] = useState(null);
    const [hideMainnetModal, setHideMainnnetMModal] = useState(false);
   
    useEffect(()=> {
        if (web3Modal && web3Modal.cachedProvider) {
            onConnect();
        }
    } , [])


    const getWeb3 = (provider)=> {
        const web3 = new Web3(provider);
        web3.eth.extend({
        methods: [
            {
                name: "chainId",
                call: "eth_chainId",
                outputFormatter: web3.utils.hexToNumber
            },
            {   
                name: 'getTransactionReceipt',
                call: 'eth_getTransactionReceipt',
                params: 1,
                inputFormatter: [null],
                outputFormatter: (data) => {
                    console.log("Tx DATTA", data);
                    return data;
                }
            }
        ]
        });
        return web3;
    }

    const onConnect = async () => {
        const provider = await web3Modal.connect();
        await subscribeProvider(provider);
        await provider.enable();
        const web3 = getWeb3(provider);
        const accounts = await web3.eth.getAccounts();
        const networkId = await web3.eth.net.getId();
        const chainId = await web3.eth.chainId();
        setWeb3(web3);
        setProvider(provider);
        // const bal  = await web3.eth.getBalance(accounts[0]);
        if(accounts.length>0) {
            const data = {
                web3, accAddress:  accounts[0], networkId, chainId, connected: true
            }
            props.updateAccount(data);
            if (isChainValid(chainId)) {
                setAccountAndBalances(web3, data.accAddress);
                // props.fetchMainnetAccount(data.accAddress);
                setHistoricData(chainId);
            }
        }
        
    }

    const setHistoricData = async (chainId) => {
        // props.fetchHistoricData(chainId, 1);
        // props.fetchHistoricData(chainId, 30);
    }

    const setAccountAndBalances = (web3, accAddress) => {
        const contract = getContract(web3, libABI, props.libAddress);
        props.fetchAccountSnapshot(contract, accAddress, props.assets.map((item)=> item.get("address")).toJS());
        props.fetchNativeBalance(web3, accAddress);
        // props.fetchTransactions("mumbai", accAddress);
    }

    const subscribeProvider = async (provider) => {
        if (!provider.on) {
            console.log("not provider on");
            return;
        }
        // provider.on("close", () => this.resetApp());
        provider.on("accountsChanged", async (_accounts) => {
            console.log("accounts changed", _accounts[0]);
           
            const web3 = getWeb3(provider);
            const chainId = await web3.eth.chainId();
            props.updateAccount({accAddress: _accounts[0], chainId}) 
            if(isChainValid(chainId)) {
                setAccountAndBalances(new Web3(provider), _accounts[0]);
                // props.fetchMainnetAccount(_accounts[0]);
                setHideMainnnetMModal(false);
            }
        });
        provider.on("chainChanged", async (_chainId) => {
            // const _networkId = await web3.eth.net.getId();
            const cId = Web3.utils.hexToNumber(_chainId);
            console.log("chain changed", cId, "valid", isChainValid(cId));
            const web3 = getWeb3(provider);
            const accounts = await web3.eth.getAccounts();
            props.updateAccount({chainId: cId, accAddress: accounts[0]});
            if(isChainValid(cId)) {
                setAccountAndBalances(web3, accounts[0]);
                // props.fetchMainnetAccount(accounts[0]);
                setHideMainnnetMModal(false);
                setHistoricData(cId);
            }
        });

        // provider.on("networkChanged", async (_networkId) => {
        //     const _chainId = await web3.eth.net.chainId();
        //     props.updateAccount({chainId: _chainId, networkId: _networkId});
        // //   await this.getAccountAssets();
        // });
    };

    const disconnect = async()=> {
        await web3Modal.clearCachedProvider();
        if(provider.close) {
            console.log("Closing session");
            await provider.close();
        }
        resetAcoount();
    };

    const resetAcoount = async ()=> {
        props.updateAccount({web3: null, accAddress: "", networkId: null, chainId: null, connected: false, provider: null});
    }

    const isChainValid = (cId) => {
       return props.supportedChains.find((c)=> c.get("chainId") === cId) !== undefined;
    }

    return (
        <div className={`dapp-container position-relative ${props.themeMode === "dark" ? "bg-black": ""}`}>
            <NavBar 
                onConnect = {()=> onConnect()}
                onDisconnect= {()=> disconnect()}
            />
            {/* {props.mainnetAccount.get("isFetched") && <h3>{props.mainnetAccount.get("status")}  -  {props.mainnetAccount.get("message")}</h3>} */}
            {/* <div className="section-bg"></div> */}
            {/* {props.web3 !== null &&  <TestLiq accAddress={props.accAddress} web3={web3}/> } */}
            {isChainValid(props.chainId) && props.connected && <Routes>
                <Route path="/" element={<Lend />} />
                <Route path="/borrow" element={<Borrow />} />
                <Route path="portfolio" element={<Portfolio />} />
            </Routes>}
            {!isChainValid(props.chainId) && props.connected && <div className="mt-5">
                <h6 className="text-center">Chain {props.chainId} is not supported.<br/>Please switch to {props.supportedChains.map((a)=> a.get("name")).join(" / ")}  network.</h6>
                <ConnectHelp connected={props.connected}/>
            </div>}
            {!props.connected && <ConnectHelp providers={providers} themeMode={props.themeMode}  onConnect = {()=> onConnect()} connected={props.connected}/>}
            {/* {props.web3 !== null && <TestUni accAddress={props.accAddress} web3={web3}/>} */}
            {props.transaction.get("isActive") === true && <TransactionModal
                refreshBalances={(newBlockNumber)=> {
                    setAccountAndBalances(web3, props.accAddress);
                    setTimeout(()=> {
                        setAccountAndBalances(web3, props.accAddress);
                    }, 3000);
                }}
                show={props.transaction.get("isActive") === true}
                onHide={()=> props.resetTransaction()}
            />}
            {/* <footer className="footer mt-5 py-3 text-center text-muted"> 
                <div className="container">
                    We would love to hear your thoughts or feedback on how we can improve your experience!
                    <br/><a className="ms-2" target="_blank" rel="noreferrer" href="https://forms.gle/YCHKVq9JMPskLGVG6">Submit Feedback<i className="ms-1 fa fa-arrow-up-right-from-square"></i></a>
                </div>
            </footer> */}
            {/* <MainnetModal 
                data = {props.mainnetAccount}
                show={hideMainnetModal === false && props.mainnetAccount.get("isFetched") && props.mainnetAccount.get("status") === 0}
                onHide={()=> setHideMainnnetMModal(true)}
                themeMode={props.themeMode}
                updateMainnetAccount={(address)=> props.updateMainnetAccount({
                    "address": props.accAddress,
                    "mainnetAddress": address
                })}
            /> */}
        </div>
    );
}

const mapStateToProps = state => {
    return {
        network: state.get("network"),
        chainId: state.get("chainId"),
        connected: state.get("connected"),
        supportedChains: state.get("supportedChains"),
        libAddress: state.getIn(["contracts", "libABI"]),
        nftAddress: state.getIn(["contracts", "nft"]),
        assets: state.get("assets"),
        transaction: state.get("transaction"),
        web3: state.get("web3"),
        accAddress: state.get("accAddress"),
        themeMode: state.get("themeMode"),
        mainnetAccount: state.get("mainnetAccount"),
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateAccount: data => dispatch(updateAccount(data)),
        fetchAccountSnapshot: (contract, accAddress, tokens) => dispatch(fetchAccountSnapshot(contract, accAddress, tokens)),
        resetTransaction: ()=> dispatch(resetTransaction()),
        // fetchMainnetAccount: (address)=> dispatch(fetchMainnetAccount(address)),
        // updateMainnetAccount: (data) => dispatch(updateMainnetAccount(data)),
        fetchNativeBalance: (web3, address) => dispatch(fetchNativeBalance(web3, address)),
        fetchTransactions: (network, address) => dispatch(fetchTransactions(network, address)),
        // fetchHistoricData: (chainID, days) => dispatch(fetchHistoricData(chainID, days))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
