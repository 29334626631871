import { useState } from 'react';
import {connect} from 'react-redux';
import {Card, Row, Col, Button} from 'react-bootstrap';

import {getTokenIcon, fixed2Decimals, toPrecisin} from '../../../utils';
import BorrowModal from '../../dashboard/components/BorrowModal';
import LendModal from '../../dashboard/components/LendModal';
import { fromJS } from 'immutable';
import SettleModal from '../../dashboard/components/SettleModal';

function BorrowingAssets(props) {
    const [activeToken, setActiveToken] = useState(null);
    const [activeWithdrawToken, setActiveWithdrawToken] = useState(null);
    const [activeSettleToken, setActiveSettleToken] = useState(null);

    let borrowAsset = props.assets.find((item)=> item.get("borrowEnabled"));
    let boughtAssets = [];
    props.assets.filter((item)=> item.get("buyEnabled") === true).map((asset)=> {
        if (props.accountSnapshot.hasIn([asset.get("address"), "lendBalance"]) && Number(props.accountSnapshot.getIn([asset.get("address"), "lendBalance"])) > 0) {
            boughtAssets.push(asset);
        }
    })

    const getAPY = (address) => {
        let apy = props.accountSnapshot.getIn([address, "borrowAPY"]);
        return apy ? `${toPrecisin(apy, 4)}%` : "---" ;
    }

    const getLendAPY = address => {
        let apy = props.accountSnapshot.getIn([address, "lendAPY"]);
        return apy ? `${toPrecisin(apy, 4)}%` : "---" ;
    }

    let borrowBalance = props.accountSnapshot.hasIn([borrowAsset.get("address"), "borrowBalance"]) ? props.accountSnapshot.getIn([borrowAsset.get("address"), "borrowBalance"]) : 0;
    // let borrowBalanceDisplay = toPrecisin(fixed2Decimals(borrowBalance, borrowAsset.get("decimals")), 2);
    let borrowBalanceDisplay = fixed2Decimals(borrowBalance, borrowAsset.get("decimals"), 4);
    return (
        <Card body className="mt-5">
             <Row>
                 <Col className="g-0">
                    <div className="fs-6">Investments</div>
                    <div className="small text-muted">Tokens you have borrowed for a collateral</div>
                </Col>
             </Row>
            <div className="token-list">
                {boughtAssets.map((token, j)=> {
                    return (
                        <Row key={j} className="token-list-item ">
                            <Col>
                                <Row>
                                    <Col>
                                        <div className="title">Asset</div>
                                        <div className="value">
                                            <img height="16" src={getTokenIcon(token.get("symbol"))} alt="token"/>
                                            {token.get("symbol")}
                                        </div>
                                    </Col>
                                
                                    <Col>
                                        <div className="title">APY</div>
                                        <div className="value">{getAPY(borrowAsset.get("address"))}</div>
                                    </Col>
                                </Row>
                                <Row className="mt-3">
                                    <Col className="text-dark fs-5 ">
                                        {fixed2Decimals(props.accountSnapshot.getIn([token.get("address"), "lendBalance"]), token.get("decimals"), 4)}
                                        &nbsp;{token.get("symbol")}
                                    </Col>
                                </Row>
                            </Col>
                            <Col xs={3}>
                                {/* <Button variant="outline-secondary mb-2" disabled>Stake</Button> */}
                                {/* <Button variant="outline-secondary mb-2" disabled>Sell on DEX</Button> */}
                                <div className='d-grid'>
                                    <Button variant="primary align-bottom" onClick={()=> setActiveSettleToken(token)}>Sell</Button>
                                    <Button variant="primary align-bottom mt-2"  onClick={()=> setActiveWithdrawToken(token)}>Withdraw</Button>
                                </div>
                                
                            </Col>
                        </Row>
                    )
                })}
                <Row className="token-list-item">
                    <Col>
                        <div className="title">Borrowed In</div>
                        <img className="me-1" height="16" src={getTokenIcon(borrowAsset.get("symbol"))} alt="token"/>    
                        {borrowAsset.get("symbol")}
                    </Col>
                    <Col>
                        <div className="title">Borrow Amount Total</div>
                        <img height="16" className="me-1" src={getTokenIcon(borrowAsset.get("symbol"))} alt="token"/>
                        {borrowBalanceDisplay}
                    </Col>
                    <Col className="col-3">
                        <div className='d-grid'>
                            <Button variant="primary" className="" onClick={()=> setActiveToken(fromJS({
                                targetToken: props.assets.find((item)=> item.get("buyEnabled")),
                                borrowAssets: props.assets.filter((item)=> item.get("borrowEnabled"))
                            }))}>Repay</Button>
                        </div>
                    </Col>

                </Row>
            </div>
            {activeToken !==null && <BorrowModal
                repayOnly={true}
                activeTab="Repay"
                show={activeToken !== null}
                tokens={activeToken}
                getAPY={(address) => getAPY(address)}
                onHide={()=> setActiveToken(null)}
            />}

            {activeWithdrawToken !==null && <LendModal
                activeTab="Withdraw"
                show={activeWithdrawToken !== null}
                token={activeWithdrawToken}
                lendAPY={getLendAPY(activeWithdrawToken.get("address"))}
                onHide={()=> setActiveWithdrawToken(null)}
            />}
            {activeSettleToken !==null && <SettleModal
                show={activeSettleToken !== null}
                token={activeSettleToken}
                // borrowAssets={borrowAssets.filter((item)=> item.get("address") !== activeSettleToken.get("address"))}
                borrowAssets={props.assets.filter((item) => activeSettleToken.get("borrowAssets").includes(item.get("address")))}
                onHide={()=> setActiveSettleToken(null)}
            />}
        </Card>
    )
}

const mapStateToProps = state => {
    return {
        assets: state.get("assets"),
        accountSnapshot: state.get("accountSnapshot"),
    }
}

export default connect(mapStateToProps)(BorrowingAssets);